/*
 * Content:
 *
 * Form control
 * Switch
 */


// Form control
// ============================================================================

.form-control {
  &:disabled,
  &:disabled::placeholder {
    color: $input-disabled-color;
  }
}

// Switch
// ============================================================================
.form-check.form-switch {
  .form-check-input {
    border: 1px solid $gray-500;

    &:checked {
      border: none;
    }

    &:not(:checked) {
      background-color: $input-disabled-color;
      border: none;

      &:focus {
        background-color: $light;
      }
    }

    &:disabled {
      &:not(:checked) {
        background-color: $light;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-2 -1 16 16' fill='none'%3E%3Crect x='0.833313' y='0.833332' width='12.3333' height='12.3333' rx='6.16667' fill='%23F8F9FA' stroke='%23CED4DA'/%3E%3C/svg%3E");
        border: 1px solid $gray-400;
      }

      &:checked {
        background-color: $light;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='-3 -3 20 20' fill='none'%3E%3Crect x='0.333313' y='0.333336' width='13.3333' height='13.3333' rx='6.66667' fill='white'/%3E%3C/svg%3E");
      }
    }
  }
}
