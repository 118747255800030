/*
 * Content:
 *
 * SavingsCostJointChart
 */


// SavingsCostJointChart
// ============================================================================
.savings-cost-joint-chart {
  .recharts-surface {
    .recharts-yAxis {
      .recharts-cartesian-axis-ticks {

        // Hide bottom and top y axis values
        .recharts-cartesian-axis-tick {

          &:first-child,
          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .recharts-cartesian-grid {

    // Hide bottom and top horizontal lines
    .recharts-cartesian-grid-horizontal {

      :first-child,
      :last-child {
        display: none;
      }
    }

    // Hide all vertical lines associated to each tick
    .recharts-cartesian-grid-vertical {
      display: none;
    }
  }

  // Hide line on each value tick indicator
  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .axis-label {
    font-weight: $font-weight-semi-bold;
    fill: $primary;
  }
}

.recharts-legend-wrapper {
  width: 90% !important;
}