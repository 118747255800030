/*
 * Content:
 *
 * Font size base
 * Font size small
 * Font size smaller
 * Font size xs
 */

.fs-base {
  font-size: $font-size-base // 16px;
}

.fs-small {
  font-size: $font-size-sm; // 14px;
}

.fs-smaller {
  font-size: $font-size-small // 12px;
}

.fs-xs {
  font-size: $font-size-xs // 8px;
}
