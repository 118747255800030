/*
 * Content:
 *
 * Infograph container
*/

.infograph-container {
  flex-grow: 1;
  max-width: 800px;
  background-color: $light;
  border-radius: $border-radius-xxl;

  .divider {
    height: 1.75rem;
    background-color: $secondary;
  }

  .recharts-surface {
    overflow: visible;
  }
}
