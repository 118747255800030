/*
 * Content:
 * Policy Projection Content
 * Policy Table
 */


// Policy Projection Content Body
// ============================================================================
#policy-projection-content-body {

  // `!important`constraints are needed in order to overwrite `!important` instructions set by Bootstrap in `mx-<size>` and `<px-<size>` instructions
  // `@media print` only targets printing view so there shouldn't be any issue
  @media print {
    margin-inline: 0 !important;
    padding-inline: 0 !important;
  }
}

// Policy Projection Help Section
// ============================================================================
#policy-help-section {

  // `!important`constraints are needed in order to overwrite `!important` instructions set by Bootstrap in `mx-<size>` and `<px-<size>` instructions
  // `@media print` only targets printing view so there shouldn't be any issue
  @media print {
    margin-left: 1rem !important;
    break-inside: avoid;
  }
}

// Policy Table
// ============================================================================
table.policy-table {
  width: 100%;
  box-shadow: 0 4px 12px 0 #151f381a;

  th {
    font-weight: $font-weight-semi-bold;
    color: $primary;
    text-align: center;
    background-color: $light-secondary;
    padding-block: $big-th-padding;
    padding-inline: spacer(4);
  }

  tr {
    border-bottom: $border-base;

    &:nth-child(2n) {
      background-color: $gray-100;
    }

    td {
      padding: spacer(4);
      color: $primary;
      text-align: center;
    }
  }
}