/*
 * Content:
 *
 * Width as max content
 * Width as min content
 */

.w-max-content {
  width: max-content;
}

.w-min-content {
  width: min-content;
}
