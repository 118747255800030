/*
 * Content:
 *
 * Card Shadow
 */

// Card Shadow
// ============================================================================

.card-shadow {
  box-shadow: 0 4px 12px 0 #151f381a;
}
