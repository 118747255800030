/*
 * Content:
 *
 * Simulation Result Cards
 * Cards Active
 * Cards Box
 * Cards Separator
 * Cards Highlight
 * Big Result Cards
 * Summation boxes wrapper
 */

// Simulation Result Cards
// ============================================================================

.simulation-result-cards {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
  max-width: 600px;

  .summation-boxes {
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    gap: .5rem;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 2fr 1fr;
  }
}

// Card Active
// ============================================================================

.card.active,
.card-box {
  border: .125rem solid $secondary;
  box-shadow: 0 1rem 1.5rem rgba($gray-900, .07);
}

// Card Box
// ============================================================================

.card-box {
  position: relative;
  background-color: $white;
  border: .0625rem solid $secondary;
  border-radius: $border-radius-xl;
}

.card-box.arrow::after {
  position: absolute;
  right: 1rem;
  bottom: -.5625rem;
  left: 0;
  width: 1rem;
  height: 1rem;
  margin-right: auto;
  margin-left: auto;
  content: "";
  background: $white;
  border-color: $secondary;
  border-style: solid;
  border-width: .0625rem .0625rem 0 0;
  transform: rotate(135deg);
}

.card-box .box {
  background-color: rgba($secondary, .2);
  border-radius: $border-radius-xl;
}


@include media-breakpoint-up(md) {
  .card-box.arrow::after {
    top: 0;
    right: -.5625rem;
    bottom: 0;
    margin-top: auto;
    margin-right: 0;
    margin-bottom: auto;
    transform: rotate(45deg);
  }
}

// Card Separator
// ============================================================================

.card-separator {
  position: relative;
}

.card-separator::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25%;
  content: "";
  border-bottom: .125rem solid $primary;
}

// Card Separator
// ============================================================================

.card-highlight {
  position: absolute;
  top: 0;
  top: -1rem;
  right: 0;
  padding: .25rem .5rem;
  margin-right: 1rem;
  color: $black;
  background: $light-cyan;
  border-radius: .25rem;
}


// Big Simulation Result Cards
.big-simulation-result-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.big-simulation-result-cards-reverse {
  flex-direction: column-reverse;

  @include media-breakpoint-up(md) {
    flex-direction: row-reverse;
  }

  .summation-boxes-wrapper .arrow {
    bottom: 100%;
  }
}

// Summation boxes wrapper
.summation-boxes-wrapper {
  position: relative;
  flex-grow: 1;

  .summation-boxes {
    display: grid;
    grid-template-columns: 1fr 1.5rem 1fr;
    align-items: center;
    gap: 1rem;
  }

  .arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1rem;
    height: 1rem;
    background: $white;

    transform: translate(-50%, 50%) rotate(-45deg);

    @include media-breakpoint-up(md) {
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);

      &[data-arrow-direction="left"] {
        left: 0;
      }

      &[data-arrow-direction="right"] {
        left: 100%;
      }
    }
  }
}

.total-box {
  flex-grow: 1;
}
