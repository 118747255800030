/*
 * Content:
 *
 * Simulation renderer
 * Simulation renderer shadow
*/


// Simulation renderer
// ============================================================================

.simulation-renderer {
  flex-grow: 1;
  width: 100%;
  padding: spacer(3);
  background-color: $light;
  border: 1px solid $light;
  border-radius: $border-radius-xl;

  .recharts-surface {
    overflow: visible;
  }

  @include media-breakpoint-up(md) {
    padding: spacer("3_5");
  }
}


// Simulation renderer shadow
// ============================================================================

.simulation-renderer-shadow {
  border: 1px solid $gray-200;
  border-radius: $border-radius-xxl;

  // Custom box shadow
  box-shadow: 0 4px 12px rgb(21 31 56 / 10%);
}
