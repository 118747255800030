/*
 * Content:
 *
 * Extended border radius values
 */

.rounded-4 {
  border-radius: spacer(2);
}

.rounded-5 {
  border-radius: spacer(3);
}
