/*
 * Content:
 *
 * Modules
 * Paths
 * Color system
 * Spacing
 * Body
 * Grid breakpoints
 * Utilities maps
 * Components
 * Font Family
 * Typography
 * Borders
 * Alerts
 * Buttons
 * Cards
 * Navbar
 * Footer
 * Forms
 * Select
 * Checkboxes
 * Accordion
 * Dropdown
 * Close
 * Bootstrap variables
 * Choices variables
 */


// Modules
// ============================================================================

@use "sass:map";


// Paths
// ============================================================================

$images-path: "../img/";
$fonts-path: "../fonts/";


// Color system
// ============================================================================

// Grays
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #333840;
$gray-900: #212529;
$black: #000;
$light-gray: #939799;
$gray-text: #afbacc;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$grays-rgb: map-loop($grays, to-rgb, "$value");

// Colors
$blue: #1c7cd5;
$blue-subtle: #ddebf9;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e17dac;
$red: #d9534f;
$light-red: #f9e5e5;
$orange: #ee9800;
$light-orange: #fdf0d9;
$yellow: #f1a946;
$light-yellow: #fef3cb;
$green: #5cb85c;
$light-green: #e8f6ee;
$teal: #20c997;
$cyan: #0dcaf0;
$mid-blue: #0c4497;
$dark-blue: #042164;
$mid-cyan: #00b0b9;
$light-cyan: #d9f3f5;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $light-gray,
  "gray-dark": $gray-800
);

// Themes
$primary: $mid-blue;
$secondary: $mid-cyan;
$light-secondary: $light-cyan;
$tertiary: $dark-blue;
$success: $green;
$light-success: $light-green;
$info: $blue;
$info-bg-subtle: $blue-subtle;
$light-info: $info-bg-subtle;
$warning: $orange;
$light-warning: $light-orange;
$danger: $red;
$light-danger: $light-red;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light-secondary": $light-secondary,
  "success": $success,
  "light-success": $light-success,
  "info": $info,
  "light-info": $light-info,
  "warning": $warning,
  "light-warning": $light-warning,
  "danger": $danger,
  "light-danger": $light-danger,
  "light": $light,
  "dark": $dark,
  "gray": $light-gray
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// Spacing
// ============================================================================

$spacer: 1rem;
$spacers: (
  0: 0,
  // 4px
  1: ($spacer * .25),
  // 8px
  2: ($spacer * .5),
  // 12px
  "2_5": ($spacer * .75),
  // 16px
  3: $spacer,
  // 24px
  "3_5": ($spacer * 1.5),
  // 32px
  4: ($spacer * 2),
  // 48px
  "4_5": ($spacer * 3),
  // 64px
  5: ($spacer * 4)
);


// Body
// ============================================================================

$body-color: $gray-900;
$body-bg: $white;


// Grid breakpoints
// ============================================================================

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 634px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Utilities maps
// ============================================================================

$utilities-colors: map.merge(
  map.merge(
    $theme-colors-rgb,
    map-add-key-prefix($grays-rgb, gray-)
  ),
  ("black": to-rgb($black),
  "white": to-rgb($white),
  "body": to-rgb($body-bg))
);

// Components
// ============================================================================

$shadow-color: rgb(0 0 0 / 22%);


// Font Family
$font-family-sans-serif: "Poppins", sans-serif;
$font-family-base: $font-family-sans-serif;

// Typography
// ============================================================================

$enable-rfs: false;

$font-weight-medium: 500;

$font-weight-semi-bold: 600;

$font-size-base-in-px: 16px;

// font-size bootstrap reference for use font-size bootstrap mixin (responsive font size)
// see bootstrap/scss/_type.scss
// see bootstrap/scss/vendor/_rfs.scss
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;
$font-size-xs: $font-size-base * .5;

$line-height-base: 1.5;

// Headings
$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.375;
$h5-font-size: $font-size-lg;
$h6-font-size: $font-size-base * 1.125;

$headings-line-height: $line-height-base;

// Displays
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display-line-height: $headings-line-height;

// Lead paragraph
$lead-font-size: $font-size-base * 1.25;

// Small text
$font-size-small: .75rem;


// Text Muted Color
$text-muted: rgba($gray-500, .8);

// Borders
// ============================================================================

$border-radius-xl: .5rem;
$border-radius-xxl: 1rem;

$border-base: 1px solid $gray-300;

// Alerts
// ============================================================================

$alert-border-radius: $border-radius-xl;

// Buttons
// ============================================================================

$btn-padding-y: rem(6px);
$btn-padding-x: 2rem;
$btn-font-weight: 700;
$btn-border-width: 2px;

$btn-hover-bg-shade-amount: 15%;

$btn-border-radius: $border-radius-xl;


// Cards
// ============================================================================

$card-border-radius: $border-radius-xl;

// Navbar
// ============================================================================

$navbar-min-height: 72px;
$navbar-nav-item-link-padding-x: 14.4px;

// Footer
// ============================================================================

$footer-min-height: 56px;


// Forms
// ============================================================================

$input-color: $body-color;

$input-disabled-color: $gray-200;

$input-border-radius: $border-radius-xl;

$form-floating-label-opacity: 1;

$input-disabled-bg: $white;

$form-select-disabled-bg: $white;

// Select
$form-select-disabled-color: $input-disabled-color; // This value can't be `null` for overriding choices styles

// Checkboxes
// ============================================================================

$form-check-input-disabled-opacity: 1;

$form-check-input-checked-bg-color: $secondary;

$form-check-input-border-radius: 2.5rem;

// Switch
$form-switch-width: 1rem * 2.5;
$form-switch-padding-start: $form-switch-width + spacer(3);
$form-switch-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='2 1 20 20' fill='none'%3E%3Cg filter='url(%23filter0_ddd_7774_55736)'%3E%3Crect x='5.33334' y='4.33333' width='13.3333' height='13.3333' rx='6.66667' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_ddd_7774_55736' x='0.333344' y='0.333332' width='23.3333' height='23.3333' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='2.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow_7774_55736' result='effect2_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/%3E%3CfeBlend mode='normal' in2='effect2_dropShadow_7774_55736' result='effect3_dropShadow_7774_55736'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_7774_55736' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
$form-switch-focus-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='2 1 20 20' fill='none'%3E%3Cg filter='url(%23filter0_ddd_7774_55736)'%3E%3Crect x='5.33334' y='4.33333' width='13.3333' height='13.3333' rx='6.66667' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_ddd_7774_55736' x='0.333344' y='0.333332' width='23.3333' height='23.3333' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='2.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow_7774_55736' result='effect2_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/%3E%3CfeBlend mode='normal' in2='effect2_dropShadow_7774_55736' result='effect3_dropShadow_7774_55736'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_7774_55736' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
$form-switch-checked-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='2 1 20 20' fill='none'%3E%3Cg filter='url(%23filter0_ddd_7774_55736)'%3E%3Crect x='5.33334' y='4.33333' width='13.3333' height='13.3333' rx='6.66667' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_ddd_7774_55736' x='0.333344' y='0.333332' width='23.3333' height='23.3333' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='2.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow_7774_55736' result='effect2_dropShadow_7774_55736'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'/%3E%3CfeBlend mode='normal' in2='effect2_dropShadow_7774_55736' result='effect3_dropShadow_7774_55736'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_7774_55736' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

// Accordion
// ============================================================================

$accordion-button-focus-box-shadow: none;

// Dropdown
// ============================================================================

$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: shade-color($primary, $btn-hover-bg-shade-amount);

// Popover
// ============================================================================
$popover-max-width: 100%;
$popover-bg: $primary;
$popover-body-color: $white;
$popover-font-size: $font-size-small;


// Close
// ============================================================================

$btn-close-color: $black;
$btn-close-bg: url("data:image/svg+xml,<svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.26396 4.00002L7.15923 2.10476C7.3918 1.87218 7.3918 1.4951 7.15923 1.26233L6.73801 0.841119C6.50544 0.608543 6.12836 0.608543 5.89559 0.841119L4.00033 2.73638L2.10506 0.841119C1.87248 0.608543 1.4954 0.608543 1.26264 0.841119L0.841424 1.26233C0.608848 1.49491 0.608848 1.87199 0.841424 2.10476L2.73669 4.00002L0.841424 5.89529C0.608848 6.12786 0.608848 6.50494 0.841424 6.73771L1.26264 7.15892C1.49521 7.3915 1.87248 7.3915 2.10506 7.15892L4.00033 5.26366L5.89559 7.15892C6.12817 7.3915 6.50544 7.3915 6.73801 7.15892L7.15923 6.73771C7.3918 6.50513 7.3918 6.12805 7.15923 5.89529L5.26396 4.00002Z' fill='#{$btn-close-color}'/></svg>");

// Tables
$big-th-padding: 3.25rem;


// Bootstrap variables
// ============================================================================

@import "~bootstrap/scss/variables";


// Choices variables
// ============================================================================

$choices-guttering: 0;
$choices-border-radius: $form-select-border-radius;
$choices-bg-color: $form-select-bg;
$choices-bg-color-disabled: $form-select-disabled-bg;
$choices-keyline-color: $form-select-border-color;

// Dropdown
$choices-bg-color-dropdown: $gray-100;
$choices-dropdown-border-width: 0;
$choices-dropdown-border-color: $choices-keyline-color;

$choices-dropdown-item-paddiny-y: $form-select-padding-y;
$choices-dropdown-item-paddiny-x: $form-select-padding-x;
$choices-dropdown-item-font-size: $font-size-base;
$choices-dropdown-item-line-height: $line-height-base;
$choices-dropdown-item-color: $dropdown-link-color;

$choices-dropdown-item-active-color: $dropdown-link-active-color;
$choices-dropdown-item-active-bg: $dropdown-link-active-bg;

$choices-dropdown-item-hover-color: $dropdown-link-hover-color;
$choices-dropdown-item-hover-bg: $dropdown-link-hover-bg;

$choices-dropdown-item-disabled-color: $dropdown-link-disabled-color;

$choices-dropdown-group-item-padding-l: $choices-dropdown-item-paddiny-x * 2;

// Multiple item
$choices-border-radius-item: $badge-border-radius;
$choices-multiple-item-padding-y: 0;
$choices-multiple-item-padding-x: spacer(1);
$choices-multiple-item-bg: $gray-100;
$choices-multiple-item-font-size: $font-size-base;
$choices-multiple-item-line-height: $line-height-base;
$choices-multiple-item-color: $secondary;
$choices-multiple-item-border-width: $border-width;
$choices-multiple-item-border-color: $choices-multiple-item-color;
$choices-multiple-item-spacer: spacer(2);

$choices-multiple-item-disabled-bg: $form-select-disabled-bg;
$choices-multiple-item-disabled-color: $choices-multiple-item-color;
$choices-multiple-item-disabled-border-color: $choices-multiple-item-border-color;

// Remove button
$choices-button-dimension: .5rem;
$choices-button-offset: spacer(1);
$choices-button-color: $choices-multiple-item-color;
$choices-button-disabled-color: $choices-multiple-item-disabled-color;
$choices-button-focus-shadow: $btn-close-focus-shadow;

$choices-icon-cross-svg: str-replace($btn-close-bg, #{$btn-close-color}, #{$choices-button-color});
$choices-icon-cross: escape-svg($choices-icon-cross-svg);

// Heading
$choices-heading-padding-y: $choices-dropdown-item-paddiny-y;
$choices-heading-padding-x: $choices-dropdown-item-paddiny-x;
$choices-heading-font-weight: $font-weight-base;
$choices-heading-font-size: $font-size-base;
$choices-heading-line-height: $line-height-base;
$choices-heading-color: $gray-700;
$choices-heading-border-width: $border-width;
$choices-heading-border-color: $border-color;
